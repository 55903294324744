import {
  createContext, PropsWithChildren, useContext, useState,
} from 'react';
import { TrackingInfo, TrackingOrder } from '../../../../hooks/api/order';

interface TrackingContextProps {
  order: TrackingOrder | null;
  setOrder: (order: TrackingOrder | null) => void
  tracking: TrackingInfo | null;
  setTracking: (tracking: TrackingInfo | null) => void
  trackingNumber: string | null;
  setTrackingNumber: (trackingNumber: string | null) => void

}

const defaultValue: TrackingContextProps = {
  order: null,
  setOrder: () => {
    // default
  },
  tracking: null,
  setTracking: () => {
    // default
  },
  trackingNumber: null,
  setTrackingNumber: () => {
    // default
  },
};

export const TrackingContext = createContext<TrackingContextProps>(defaultValue);
function TrackingProvider({ children }: PropsWithChildren) {
  const [order, setOrder] = useState<TrackingOrder | null>(null);
  const [tracking, setTracking] = useState<TrackingInfo | null>(null);
  const [trackingNumber, setTrackingNumber] = useState<string | null>(null);

  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <TrackingContext.Provider value={{
      order,
      setOrder,
      tracking,
      setTracking,
      trackingNumber,
      setTrackingNumber,
    }}
    >
      {children}
    </TrackingContext.Provider>
  );
}

export default TrackingProvider;

export const useContextTracking = (): TrackingContextProps => useContext(TrackingContext);
