import { useState, ChangeEvent } from 'react';
import {
  Form, Input, Select, SelectProps,
} from 'antd';
import clsx from 'clsx';

import floatStyles from '../index.module.scss';
import styles from './index.module.scss';

const { Option } = Select;

interface AddonProps {
  label: string;
  value?: string | number;
  required?: boolean;
  formName?: number | undefined;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  addonName: string;
  options: {
    label: string,
    value: string
  }[];
  min?: number;
  max?: number;
  onAddonChange?: SelectProps['onChange'];
}

function Addon({
  label, value, required, formName, onChange, options, addonName, min = 0, max, onAddonChange,
}: AddonProps) {
  const [focus, setFocus] = useState(false);
  const isOccupied = focus || (value) || value === 0;

  return (
    <div
      className={floatStyles.floatLabel}
      onBlur={() => setFocus(false)}
      onFocus={() => setFocus(true)}
    >
      <Input
        variant="borderless"
        size="large"
        onChange={onChange}
        className={clsx(floatStyles.input, styles.input)}
        type="number"
        value={value}
        min={min}
        max={max}
        addonAfter={(
          <Form.Item className={styles.inner} name={formName !== undefined ? [formName, addonName] : addonName}>
            <Select
              style={{ width: 70 }}
              onChange={(val, option) => {
                setFocus(false);
                onAddonChange?.(val, option);
              }}
            >
              {options?.map((item) => <Option selected key={item.value} value={item.value}>{item.label}</Option>)}
            </Select>
          </Form.Item>
        )}
      />

      <label className={clsx(floatStyles.label, isOccupied ? floatStyles.asLabel : floatStyles.asPlaceholder)}>
        {label}
        {' '}
        {required ? <span className="text-danger">*</span> : null}
      </label>
    </div>
  );
}

Addon.defaultProps = {
  value: '',
  placeholder: '',
  required: false,
  onChange: () => {},
  formName: undefined,
};

export default Addon;
