import { Dayjs } from 'dayjs';
import {
  DefaultFetchError,
  FetchCreate,
  useFetchCreate,
  FetchSuccess,
  useFetchGet,
  FetchGet,
  FetchGetId,
  useFetchGetId,
  AnyObject,
} from '../fetch';
import { SimpleOption } from '../../types';

export interface FileType {
  id: string;
  name: string;
  originalName?: string;
  location?: string;
  mimeType?: string;
}

export interface BasicShipperImporter {
  contactName: string;
  company: string;
  phone: string;
  email: string;
  emailForInvoices?: string;
  country: string;
  address1: string;
  address2: string;
  postalCode: string;
  city: string;
  state: string;
  tin: string;
  registerNumber: string;
  reference: string;
}

export interface Shipper extends BasicShipperImporter {
  pickupContactName: string;
  pickupCompany: string;
  pickupPhone: string;
  pickupEmail: string;
  pickupCountry: string;
  pickupAddress1: string;
  pickupAddress2: string;
  pickupPostalCode: string;
  pickupCity: string;
  pickupState: string;
  shippingType: 'courier' | string;
  pickupDateFrom: string;
  pickupDateTo: string;
  urgent: boolean;
  isPickupAddressDifferent: boolean;
}

export interface Importer extends BasicShipperImporter {
  deliveryContactName: string;
  deliveryCompany: string;
  deliveryPhone: string;
  deliveryEmail: string;
  deliveryCountry: string;
  deliveryAddress1: string;
  deliveryAddress2: string;
  deliveryPostalCode: string;
  deliveryCity: string;
  deliveryState: string;
  isDeliveryAddressDifferent: boolean;
}

export interface Good {
  oldId?: string; // Used for recognition in initial values of packages. Have to be removed in POST request!
  id?: string;
  //
  dangerousGoods: boolean;
  innerPackaging: boolean;
  unNumber: string | undefined;
  packagingGroup: string;
  hsCode: string | SimpleOption;
  packaging: string | { name: string, packageId: string };
  description: string;
  name?: string;
  quantity: number;
  massUnit: string
  currency: string
  net: number;
  gross: number;
  value: number;
  length: number;
  width: number;
  height: number;
  volume: number;
  innerPackagingQuantity: number;
  innerPackagingType: string | { name: string, innerPackageMaterialId: string };
  innerPackagingNetUnitaryQuantity : number;
  temperatureLogger: boolean;
  dualUse: boolean | string;
  realTimeMonitoring: boolean;
  exportLicense: boolean | string;
  specialTemperatureMode: boolean;
  temperatureMode: string;
  permit: string;
  permitNumber: string;
  permitDate: string | Dayjs | undefined;
  netWeightOfDryIce: number;
  exportLicenseDocument: FileType | string | undefined;
  msdsDocument: FileType | string | undefined;
  segregationGroup?: ({
    name: string;
    imdgSegregationGroupId: number;
  } | number)[];
  proformaInvoice?: any;
  [key: string]: any;
}

export enum EnumPackageKind {
  pallet = 'Pallet',
  ownPackaging = 'Own packaging',
}
export type PackageKindType = 'pallet' | 'ownPackaging';
export type CurrencyType = 'EUR' | 'CHF' | 'USD' | string;
export interface PackageItem {
  id?: string; // Not exist before creation of package
  quantity: number;
  goodsItem: Partial<Good>;
}

export interface PackagesData {
  id?: string;
  kind: PackageKindType; // Deprecated.
  length: number;
  width: number;
  height: number;
  quantity: number;
  weight: number;
  value: number;
  currency: CurrencyType;

  items: PackageItem[];
}

export interface FileArray {
  file: FileType;
}

export interface Order extends PreferredDeliveryInfo {
  id?: string;
  deliveryTerms: string | undefined;
  insurance: boolean;
  insuranceValue: number;
  insuranceCurrency: string;
  emergencyPhoneNumber?: string;
  company?: string | { id: string };
  shipper: Shipper;
  importer: Importer;
  goods: Good[];
  packages: PackagesData[];
  proformaInvoice: FileType | string | undefined;
  additionalDocuments: (FileArray | string)[];
  notes?: string;
  modeOfTransport: string | undefined;
  productType?: 'dangerous' | 'regular' | 'both';
}

export interface PreferredDeliveryInfo {
  preferredDeliveryService?: 'dhl' | 'fedex' | null,
  billingNumber?: string | null,
  billingNumberCountryCode?: string | null,
  billingCode?: BillingCode | null,
  billingType?: BillingType | null,
}

export const useOrderCreate = (): FetchCreate<
  FetchSuccess,
  DefaultFetchError,
  Order
> => useFetchCreate('orders/from-form', { // 'orders'
  authorization: false,
});

interface OrderFromApi extends Omit<Order, 'additionalDocuments'> {
  additionalDocuments: FileType[];
}

export const useOrderGetById = <DD = OrderFromApi>(
  id?: string,
  decorateData?: (data: OrderFromApi) => DD, /*= (data): DD => ({
    ...data,
    consignmentDate: validateDate(data.consignmentDate),
    createdAt: validateDate(data.createdAt),
    dataDate: validateDate(data.dataDate),
    pickupDate: validateDate(data.pickupDate),
  } as DD), */
): FetchGetId<
  OrderFromApi,
  DefaultFetchError,
  unknown,
  DD
> => useFetchGetId(
    'orders',
    id,
    {
      autoStart: !!id,
      decorateData,
      multiple: 'orders/id',
      cacheLifetime: 1000, // 1 second cache,
    },
  );

export function useDeliveryTermsGet<D = string[], DD = D>(
  decorateData?: (data: D) => DD,
): FetchGet<D, null, DefaultFetchError, DD> {
  return useFetchGet<D, DefaultFetchError, null, DD>('orders/delivery-terms', {
    name: 'deliveryTerms',
    decorateData,
    autoStart: false,
    authorization: false,
    startStateLoading: false,
  });
}
export function useMassUnitGet<D = string[], DD = D>(
  decorateData?: (data: D) => DD,
): FetchGet<D, null, DefaultFetchError, DD> {
  return useFetchGet<D, DefaultFetchError, null, DD>('goods/mass-units', {
    name: 'massUnits',
    decorateData,
    autoStart: false,
    authorization: false,
    startStateLoading: false,
  });
}
export function useCurrenciesGet<D = string[], DD = D>(
  decorateData?: (data: D) => DD,
): FetchGet<D, null, DefaultFetchError, DD> {
  return useFetchGet<D, DefaultFetchError, null, DD>('orders/currencies', {
    name: 'currencies',
    decorateData,
    autoStart: false,
    authorization: false,
    startStateLoading: false,
  });
}

export function useTemperatureModesGet<D = string[], DD = D>(
  decorateData?: (data: D) => DD,
): FetchGet<D, null, DefaultFetchError, DD> {
  return useFetchGet<D, DefaultFetchError, null, DD>(
    'goods/temperature-modes',
    {
      name: 'temperatureModes',
      decorateData,
      autoStart: false,
      authorization: false,
      startStateLoading: false,
    },
  );
}

export interface TrackingInfo<TShipment = ShipmentInfo> {
  type: string;
  shipments: TShipment[];
}

export interface ShipmentInfo {
  address: {
    name: string;
    countryCode: string;
  };
  description?: string;
  status: string;
  timestamp: string;
}

export interface DHLShipmentInfo {
  date: string; // "2024-09-03"
  time: string; // "22:42:58"
  typeCode: string; // "DF"
  description: string; // "Shipment has departed from a DHL facility BASEL-SWITZERLAND",
  serviceArea: [
    {
      'code': string; // "BSL",
      'description': string; // "Basel-CH"
    }
  ]
}

export const useTrackingDhl = <DD = TrackingInfo>(
  id?: string,
  decorateData: ((data: TrackingInfo<DHLShipmentInfo>) => DD) = (data) => ({
    type: 'DHL',
    shipments: (data.shipments || []).map((shipment) => {
      const serviceArea = shipment.serviceArea[0];

      return {
        address: {
          name: serviceArea?.description,
          countryCode: serviceArea?.code,
        },
        description: shipment.description,
        // status: shipment.typeCode === 'DF' ? 'departed' : 'transit',
        status: shipment.typeCode,
        timestamp: `${shipment.date}T${shipment.time}`, // Combine date and time into timestamp
      };
    }),
  }) as DD,
): FetchGetId<
  TrackingInfo<DHLShipmentInfo>,
  DefaultFetchError,
  unknown,
  DD
> => useFetchGetId(
    'orders/tracking/dhl',
    id,
    {
      autoStart: false, // !!id,
      decorateData,
    },
  );

// export const useTrackingDhl = (): FetchGetId<Tracking> => useFetchGetId('orders/tracking/dhl', '', {
//   autoStart: false,
//   startStateLoading: false,
// });

export const useTrackingFedex = (): FetchGetId<TrackingInfo> => useFetchGetId('orders/tracking/fedex', '', {
  autoStart: false,
  startStateLoading: false,
});

export const useTrackingDbschenker = (): FetchGetId<TrackingInfo> => useFetchGetId('orders/tracking/dbschenker', '', {
  autoStart: false,
  startStateLoading: false,
});

export interface Document {
  id: string;
  originalName: string;
  location: string;
  mimeType: string;
  createdAt: string;
}

interface DangerousGoodInner {
  classificationCode: string;
  dangerousGoodId: number;
  name: string;
  labels: string;
  packingGroup: string;
  statusADR: string;
  statusIATA: string;
  statusIMDG: string;
  unNumber: string;
}

export interface TrackingGoods {
  id: string;
  dangerousGoods: boolean;
  innerPackaging: boolean;
  unNumber: string;
  hsCode: string;
  description: string;
  currency: string;
  massUnit: 'kg' | 'L';
  quantity: number;
  net: number;
  gross: number;
  value: number;
  length: number;
  width: number;
  height: number;
  volume: number;
  innerPackagingQuantity: number;
  amountPerInnerPackage: number;
  temperatureLogger: boolean;
  dualUse: boolean;
  realTimeMonitoring: boolean;
  exportLicense: boolean;
  specialTemperatureMode: boolean;
  temperatureMode: string;
  netWeightOfDryIce: number;
  exportLicenseDocument: Document;
  msdsDocument: Document;
  name: string;
  dangerousGood: DangerousGoodInner;
  packagingGroup: string;
  englishTechName: string;
  packaging: {
    name: string;
    packageId: number;
  };
  materialAndCode: {
    code: string;
    name: string;
    packageHomologationId: number;
    packageId: number;
  };
  isEmptyUncleaned: boolean;
  innerPackagingType: {
    name: string;
    innerPackageMaterialId: number;
  };
  innerPackagingNetUnitaryQuantity: number;
  innerPackagingNetExposiveQuantity: number;
  environmentallyHazardous: boolean;
  physicalState: string;
  density: number;
  pureMixSolution: string;
  pureMixSolutionPercentage: number;
  netExplosivePerPackage: number;
  flashPoint: number;
  segregation: boolean;
  saveProductToDatabase: boolean;
  segregationGroup: {
    name: string;
    imdgSegregationGroupId: number;
  }[];
}

export interface TrackingCompany {
  id: string;
  companyName: string;
  participantType: string;
  status: string;
  contactName: string;
  phone: string;
  email: string;
  country: string;
  address1: string;
  address2: string;
  postalCode: string;
  city: string;
  state: string;
  tinNumber: string;
  registerNumber: string;
  createdAt: string;
}

export interface TrackingOrder {
  id: string;
  orderNumber: string;
  deliveryService: 'dhl' | 'fedex' | 'schenker' | 'other';
  consignmentDate: string;
  dataDate: string;
  waybillNo: number;
  companyName: string;
  adrTransportDocument: boolean;
  loadChecklist: boolean;
  packageMarking: boolean;
  marksForAllPackages: boolean;
  packageMarkingCustom: boolean;
  width: number;
  height: number;
  placeName: string;
  declarant: string;
  deliveryTerms: string;
  modeOfTransport: string;
  insurance: boolean;
  pickupDate: string;
  trackingLink: string;
  insuranceValue: number;
  IATAMultimodalDangerous: boolean;
  IATAShowRedStripes: boolean;
  IATAShowBlueStripes: boolean;
  IMDGGoodsDeclaration: boolean;
  IMDGShowBlueStripes: boolean;
  insuranceCurrency: string;
  emergencyPhoneNumber?: string;
  status: string;
  serviceName:string
  marksForAllPackagesSmall: boolean;
  company: TrackingCompany;
  shipper: {
    id: string;
    contactName: string;
    company: string;
    phone: string;
    email: string;
    country: string;
    countryCode: string;
    address1: string;
    address2: string;
    postalCode: string;
    city: string;
    state: string;
    stateCode: string;
    tin: string;
    registerNumber: string;
    reference: string;
    pickupContactName: string;
    pickupCompany: string;
    pickupPhone: string;
    pickupEmail: string;
    pickupCountry: string;
    pickupCountryCode: string;
    pickupAddress1: string;
    pickupAddress2: string;
    pickupPostalCode: string;
    pickupCity: string;
    pickupState: string;
    pickupStateCode: string;
    shippingType: string;
    pickupDateFrom: string;
    pickupDateTo: string;
    urgent: boolean;
    isPickupAddressDifferent: boolean;
  };
  importer: {
    id: string;
    contactName: string;
    company: string;
    phone: string;
    email: string;
    country: string;
    countryCode: string;
    address1: string;
    address2: string;
    postalCode: string;
    city: string;
    state: string;
    stateCode: string;
    tin: string;
    registerNumber: string;
    reference: string;
    deliveryContactName: string;
    deliveryCompany: string;
    deliveryPhone: string;
    deliveryEmail: string;
    deliveryCountry: string;
    deliveryCountryCode: string;
    deliveryAddress1: string;
    deliveryAddress2: string;
    deliveryPostalCode: string;
    deliveryCity: string;
    deliveryState: string;
    deliveryStateCode: string;
    isDeliveryAddressDifferent: boolean;
  };
  goods: TrackingGoods[];
  proformaInvoice: Document[];
  additionalDocuments: Document[];
  transportDocuments: Document[];
  trackingNumber: string;
  invoiceNotes: string;
  notes: string;
  createdAt: string;
}

export const useTrackingOrder = (): FetchGetId<TrackingOrder> => useFetchGetId('orders/by-tracking-number', '', {
  autoStart: false,
  startStateLoading: false,
});

export interface HSCodeAll {
  id: string;
  cn024: string;
  su: string;
  description: string;
  createdAt: string;
}

export interface ResponseForSelect extends AnyObject {
  value: string ;
  label: string;
}

export const useHSCodeGetAll = <DD = ResponseForSelect[]>(
  decorateData: ((data: HSCodeAll[]) => DD) =
  (data) => data.map(({ cn024, su, description }) => ({
    value: cn024,
    label: `${cn024} ${su || ''} ${description.trim() ? ` - ${description}` : ''}`
      .replace(' -  - ', ' - '),
  })) as DD,
): FetchGet<
  HSCodeAll[],
  undefined,
  DefaultFetchError,
  DD
> => useFetchGet(
    'commodity/all',
    {
      autoStart: true,
      startStateLoading: true,
      decorateData,
      multiple: 'commodity/all',
      cacheLifetime: 30 * 60 * 1000, // 30 minute cache,
    },
  );

export type BillingCode = 'SENDER' | 'RECIPIENT' | 'THIRD_PARTY' | 'COLLECT';
export type BillingType = 'ACCOUNT' | 'CREDIT_CARD' | 'RECIPIENT_ACCOUNT' | 'THIRD_PARTY_ACCOUNT';

export const useBillingOptionsGet = <DD = ResponseForSelect[]>(
  decorateData: ((data: string[]) => DD) =
  (data) => data.map((name) => ({
    value: name, label: name.replaceAll('_', ' '),
  })) as DD,
  fetchPath = 'billing-types',
): FetchGet<
  string[],
  undefined,
  DefaultFetchError,
  DD
> => useFetchGet(
    `orders/${fetchPath}`, // orders/billing-types | orders/billing-codes
    {
      autoStart: true,
      startStateLoading: true,
      decorateData,
      multiple: `orders/${fetchPath}`,
      cacheLifetime: 5 * 60 * 1000, // 5 minute cache,
    },
  );
