import React, { useState } from 'react';
import {
  Button, Col, Flex, Form, Tabs, TabsProps,
} from 'antd';
import Goods from './Goods';
import { PackageIcon, ProductIcon } from '../../../../Common/Icon';
import { Order } from '../../../../../hooks/api/order';
import { useContextForm } from '../../Context';
import PackagesForm from './Packages';

import styles from './index.module.scss';

function GoodsTab() {
  const {
    setCurrent, current, formData, setFormData,
  } = useContextForm();
  const [goodsForm] = Form.useForm();
  const [packagesForm] = Form.useForm();

  const goodsWatch = Form.useWatch([], goodsForm);

  const [isValid, setIsValid] = useState({ goods: false, packages: true });

  const handleIsValid = (key: 'goods' | 'packages', valid: boolean) => {
    setIsValid((prevState) => ({
      ...prevState,
      [key]: valid,
    }));
  };

  const handleSaveGoods = (
    validateFields: boolean,
    afterSubmitCallback?: () => void,
  ) => {
    if (validateFields) {
      const { goods, ...rest } = goodsForm.getFieldsValue();
      const { packages } = packagesForm.getFieldsValue();
      const { insuranceCurrency, insuranceValue, ...formDataValues } = formData as Order;

      setFormData({
        ...formDataValues,
        goods,
        packages,
        ...rest,
      });

      afterSubmitCallback?.();
    } else {
      goodsForm // packagesForm skip atm
        .validateFields()
        .then(() => {
          const { goods, ...rest } = goodsForm.getFieldsValue();
          const { packages } = packagesForm.getFieldsValue();
          const { insuranceCurrency, insuranceValue, ...formDataValues } = formData as Order;

          setFormData({
            ...formDataValues,
            goods,
            packages,
            ...rest,
          });

          afterSubmitCallback?.();
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const handleStep = (direction: 'next' | 'prev') => {
    if (direction === 'prev') {
      handleSaveGoods(false, () => setCurrent(current - 1));
    } else {
      handleSaveGoods(true, () => setCurrent(current + 1));
    }
  };

  const items: TabsProps['items'] = [
    {
      forceRender: true,
      key: 'goods',
      label: (
        <div className={styles.tabLabel}>
          <ProductIcon />
          Goods
        </div>
      ),
      children: (
        <Goods
          form={goodsForm}
          isValid={isValid.goods}
          setIsValid={(val) => handleIsValid('goods', val)}
        />),
    },
    {
      forceRender: true,
      key: 'packages',
      label: (
        <div className={styles.tabLabel}>
          <PackageIcon />
          Packages
        </div>
      ),
      children: (
        <PackagesForm
          form={packagesForm}
          goodsForm={goodsForm}
          isValid={isValid.packages}
          setIsValid={(val) => handleIsValid('packages', val)}
        />),
    },
  ];

  return (
    <div>
      <Tabs
        defaultActiveKey="goods"
        items={items}
        renderTabBar={(props, DefaultTabBar) => (
          <DefaultTabBar {...props} className={styles.customTabBar} />
        )}
        indicator={{ align: 'end' }}
      />

      <Col span={24}>
        <Flex justify="flex-end" gap={8} wrap="wrap">
          <Button
            size="large"
            type="default"
            id="backButton"
            onClick={() => {
              handleStep('prev');
            }}
          >
            BACK
          </Button>
          <Button
            style={{ maxWidth: '250px' }}
            size="large"
            disabled={!isValid.goods || !isValid.packages || !goodsWatch.goods?.length}
            type="primary"
            onClick={async () => {
              try {
                await goodsForm.validateFields();
                await packagesForm.validateFields();
                handleStep('next');
              } catch (error) {
                // Handle validation errors here (if needed)
                console.error('Validation failed:', error);
              }
            }}
          >
            CONTINUE TO OVERVIEW
          </Button>
        </Flex>
      </Col>
    </div>
  );
}

export default GoodsTab;
