import {
  createContext, PropsWithChildren, useContext, useEffect, useReducer, useState,
} from 'react';
import { useSearchParams } from 'react-router-dom';
import { message } from 'antd';
import dayjs from 'dayjs';
import {
  FileArray,
  FileType, Good, Order, PackagesData, PreferredDeliveryInfo, useOrderCreate, useOrderGetById,
} from '../../../../hooks/api/order';
import { useMessageError } from '../../../../hooks/common';
import { Company, useCompaniesGet } from '../../../../hooks/api/company';
import {
  InnerPackage,
  Package, UNnumber as UNnumberType, useInnerPackageGet, usePackagesGet, useUNnumberGet,
} from '../../../../hooks/api/masterdata';
import { ModeOfTransportEnum } from '../Steps/Overview/helper';

interface PrepareGoodsPackagesProps {
  goods: Good[];
  packages: PackagesData[];
}

const prepareGoodsAndPackages = ({ goods, packages }: PrepareGoodsPackagesProps): PrepareGoodsPackagesProps => {
  if (!goods?.length) { return { goods: [], packages: [] }; }

  const goodsResult: Good[] = goods.map((good) => ({
    oldId: good.id, /** Used for recognition in packages without id */
    id: crypto.randomUUID(),
    //
    unNumber: good.dangerousGood?.unNumber
      ? `${good.dangerousGood?.unNumber} ${good.dangerousGood?.name}, ${good.dangerousGood?.packingGroup}, ${
        good.dangerousGood?.classificationCode}, ${good.dangerousGood?.labels}` : undefined,
    exportLicenseDocument: good?.exportLicenseDocument || undefined,
    msdsDocument: good?.msdsDocument && typeof good?.msdsDocument === 'object'
      ? { ...good.msdsDocument, name: good.msdsDocument?.originalName } as FileType
      : undefined,
    packaging: typeof good.packaging === 'string' ? good.packaging : good.packaging?.packageId,
    /* packaging: (good?.packaging
    && typeof good?.packaging === 'object'
    && good?.packaging.packageId
      ? good?.packaging.packageId
      : good?.packaging) || null, */
    permit: good?.permit.toLowerCase(),
    permitNumber: good?.permitNumber,
    permitDate: good?.permitDate ? dayjs(good?.permitDate) : undefined,
    saveProductToDatabase: good?.saveProductToDatabase,
    dangerousGoods: good?.dangerousGoods || false,
    innerPackaging: good?.innerPackaging || false,
    dangerousGood: typeof good?.dangerousGood === 'object'
      ? good?.dangerousGood?.dangerousGoodId : good?.dangerousGood,

    packagingGroup: good?.packagingGroup,
    hsCode: (good?.hsCode
    && typeof good?.hsCode === 'object'
    && good?.hsCode.value
      ? good?.hsCode.value
      : good?.hsCode) || '',
    englishTechName: good?.englishTechName,
    name: good?.name,
    description: good?.description,
    materialAndCode: good?.materialAndCode
    && typeof good?.materialAndCode === 'object'
    && good?.materialAndCode.packageHomologationId
      ? good?.materialAndCode.packageHomologationId
      : good?.materialAndCode || null,
    isEmptyUncleaned: !!(typeof good?.isEmptyUncleaned === 'object'
      ? good?.isEmptyUncleaned.value : good?.isEmptyUncleaned),
    quantity: good?.quantity,
    value: good?.value,
    currency: good?.currency,
    net: good?.isEmptyUncleaned ? 0 : good?.net,
    massUnit: good?.massUnit,
    gross: good?.gross,
    length: good?.length,
    width: good?.width,
    height: good?.height,
    volume: good?.volume,
    innerPackagingQuantity: good?.innerPackagingQuantity,
    innerPackagingType: (good.innerPackagingType
    && typeof good?.innerPackagingType === 'object'
    && good.innerPackagingType.innerPackageMaterialId
      ? good?.innerPackagingType.innerPackageMaterialId
      : good?.innerPackagingType),
    innerPackagingNetUnitaryQuantity: good?.innerPackagingNetUnitaryQuantity,
    innerPackagingNetExposiveQuantity: good?.innerPackagingNetExposiveQuantity,
    environmentallyHazardous: good?.environmentallyHazardous || null,
    physicalState: (good.physicalState
    && typeof good?.physicalState === 'object'
    && good.physicalState.value
      ? good?.physicalState.value
      : good?.physicalState) || null,
    density: good?.density,
    pureMixSolution: good?.pureMixSolution,
    pureMixSolutionPercentage: good?.pureMixSolutionPercentage,
    netExplosivePerPackage: good?.netExplosivePerPackage,
    flashPoint: good?.flashPoint === '' ? null : good?.flashPoint,
    segregationGroup: (good?.segregationGroup || []).map((item): number => {
      if (typeof item === 'object' && item.imdgSegregationGroupId) {
        return +item.imdgSegregationGroupId;
      }

      return item as number;
    }) || null,
    dualUse: good?.dualUse || false,
    exportLicense: good?.exportLicense || false,

    /* exportLicenseDocument: (good?.exportLicenseDocument as AnyObject)?.fileList?.filter((
      { id: fileId }: AnyObject,
    ) => fileId)
      .map((
        { id }: { id: string },
      ) => id.toString())[0] || null,
    msdsDocument: (good?.msdsDocument || {})?.fileList?.filter((
        { id: fileId }: AnyObject,
      ) => fileId)?.map(({ id }: { id: string }) => (id))[0] || undefined, */

    temperatureLogger: good?.temperatureLogger || false,
    realTimeMonitoring: good?.realTimeMonitoring || false,
    specialTemperatureMode: good?.specialTemperatureMode || false,
    temperatureMode: good?.temperatureMode,
    netWeightOfDryIce: good?.netWeightOfDryIce,
  }));

  const packagesResult: PackagesData[] = (packages || []).map((packageItem) => ({
    ...packageItem,
    id: undefined,
    items: packageItem.items.map((i) => {
      const foundGoodsItem = goodsResult.find((goodsItem) => goodsItem?.oldId === i.goodsItem.id);

      return ({
        ...i,
        id: undefined,
        goodsItem: {
          ...i.goodsItem,
          name: i.goodsItem?.name || foundGoodsItem?.name || foundGoodsItem?.description,
          id: foundGoodsItem?.id || undefined,
        },
      });
    }),
  }));

  return {
    goods: goodsResult,
    packages: packagesResult,
  };
};

const formNames = ['shipper', 'importer', 'goods', 'overview'] as const;

export type FormNames = typeof formNames[number];

interface FormContextProps {
  loading: boolean;
  current: number;
  setCurrent: (value: number) => void;
  currency: string;
  setCurrency: (value: string) => void;
  notes: string | undefined;
  setNotes: (value: string) => void;
  preferredDeliveryInfo: PreferredDeliveryInfo;
  handlePreferredDelivery: (payload: Partial<PreferredDeliveryInfo>) => void;
  formData: Order | null;
  setFormData: (value: Order | null) => void;
  setForm: (name: FormNames, form: object) => void;
  createOrder: (notesProp?: string) => Promise<Order | void>;
  packages: Package[] | undefined;
  UNnumber: UNnumberType[] | undefined;
  innerPackage: InnerPackage[] | undefined;
}

export const preferredDeliveryDefaultValue = {
  preferredDeliveryService: undefined,
  billingNumber: undefined,
  billingNumberCountryCode: undefined,
  billingCode: undefined,
  billingType: undefined,
};

const defaultValue: FormContextProps = {
  loading: false,
  current: 0,
  setCurrent: () => {
    // default
  },
  currency: '',
  setCurrency: () => {
    // default
  },

  formData: null,
  setForm: () => {
    // default
  },
  setFormData: () => {
    // default
  },
  createOrder: async () => {
    // default
  },
  notes: undefined,
  setNotes: () => {
    // default
  },
  packages: undefined,
  innerPackage: undefined,
  UNnumber: undefined,
  preferredDeliveryInfo: preferredDeliveryDefaultValue,
  handlePreferredDelivery: () => undefined,
};

export const FormContext = createContext<FormContextProps>(defaultValue);
function FormProvider({ children }: PropsWithChildren) {
  const orderCreate = useOrderCreate();
  const [formData, setFormData] = useState<Order | null>(defaultValue.formData);
  const [current, setCurrent] = useState(defaultValue.current);
  const [currency, setCurrency] = useState(defaultValue.currency);
  const [notes, setNotes] = useState('');

  // eslint-disable-next-line unicorn/consistent-function-scoping
  const reducer = (prevState: PreferredDeliveryInfo, action: Partial<PreferredDeliveryInfo>) => (
    { ...prevState, ...action });
  const [preferredDeliveryInfo, dispatch] = useReducer(
    reducer,
    preferredDeliveryDefaultValue,
  );
  const handlePreferredDelivery = (payload: Partial<PreferredDeliveryInfo>) => {
    dispatch(payload);
  };

  const { loading, data } = orderCreate;
  const companiesGet = useCompaniesGet();
  const packagesGet = usePackagesGet();
  const UNnumberGet = useUNnumberGet();
  const innerPackageGet = useInnerPackageGet();
  const setForm = (name: FormNames, form: object) => {
    // @ts-ignore ____________
    setFormData((values) => {
      if (values !== null) {
        return ({ ...values, [name]: form });
      }

      return ({ [name]: form });
    });
  };

  useEffect(() => {
    UNnumberGet.fetch();
    packagesGet.fetch();
    innerPackageGet.fetch();
  }, []);

  const packages = packagesGet?.data;
  const UNnumber = UNnumberGet?.data;
  const innerPackage = innerPackageGet?.data;

  useEffect(() => {
    if (data) {
      setCurrent(current + 1);
    }
  }, [data]);

  const handleOrderFetch = (companyRes?: Company[], notesProp?: string) => {
    if (formData) {
      const values: Order = {
        ...formData,
        productType: (formData.goods || []).some((item) => item?.dangerousGoods) ? 'dangerous' : 'regular',
        insurance: !!formData.insurance,
        insuranceCurrency: formData?.goods?.[0]?.currency || 'CHF',
        goods: formData.goods.map((item) => {
          const obj = {
            ...item,
            oldId: undefined,
            dangerousGoods: item?.dangerousGoods || false,
            innerPackaging: item?.innerPackaging || false,
            temperatureLogger: item?.temperatureLogger || false,
            realTimeMonitoring: item?.realTimeMonitoring || false,
            specialTemperatureMode: item?.specialTemperatureMode || false,
            exportLicense: item?.exportLicense === 'yes',
            // There's no name field in order-form, so preset it with description first 35 symbols
            name: item?.description?.slice(0, 35) || undefined,
          };

          if (item.exportLicenseDocument) {
            obj.exportLicenseDocument = (item.exportLicenseDocument as FileType)?.id;
          }
          if (item.msdsDocument) {
            obj.msdsDocument = (item.msdsDocument as FileType)?.id;
          }

          return obj;
        }),
        packages: (formData.packages || []).length > 0 && formData.packages?.[0]?.width
          ? formData.packages?.map((packageItem) => ({
            ...packageItem,
            // New:
            id: undefined, // Clear id if item was loaded from template.
            createdAt: undefined,
            updatedAt: undefined,
            volume: undefined,
            items: (packageItem?.items || []).map((i) => ({
              id: i?.id,
              quantity: i?.quantity,
              goodsItem: { id: i?.goodsItem.id },
            })),
          })) : [],
      };

      if (notesProp || notes) {
        values.notes = notesProp || notes;
      }

      if (preferredDeliveryInfo.preferredDeliveryService) {
        Object.assign(values, preferredDeliveryInfo);
      }

      if (values.proformaInvoice) {
        values.proformaInvoice = (values.proformaInvoice as FileType).id;
      }

      if (values.additionalDocuments.length > 0) {
        // @ts-ignore id for file
        values.additionalDocuments = values.additionalDocuments.map((file) => file?.file?.id).filter((file) => file);
      }

      if (companyRes?.length) {
        values.company = companyRes[0]?.id;
      }

      orderCreate.fetch(values);
    }
  };

  const createOrder = async (notesProp?: string) => (
    handleOrderFetch(undefined, notesProp)
  );

  /** Preset form values from template */
  const orderById = useOrderGetById();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const templateId = searchParams.get('template');

    if (templateId && !formData?.goods) {
      orderById.fetch(undefined, templateId)
        .then((res) => {
          if (res?.id) {
            const {
              goods: preparedGoods, packages: preparedPackages,
            } = prepareGoodsAndPackages({ goods: res.goods, packages: res.packages });

            setFormData({
              id: undefined,
              shipper: {
                ...res.shipper,
                shippingType: res.modeOfTransport || ModeOfTransportEnum.road,
              },
              importer: res.importer,
              goods: preparedGoods || [], // prepareGoodsValues(res.goods) || [],
              packages: preparedPackages || [], // res.packages || [],

              insurance: res.insurance || false,
              proformaInvoice: undefined, // Should be unique, so dont copy that.
              deliveryTerms: res.deliveryTerms || undefined,
              emergencyPhoneNumber: res.emergencyPhoneNumber || undefined,
              additionalDocuments: [
                ...(res?.additionalDocuments || []).map((file) => ({
                  file: {
                    ...file,
                    id: file.id,
                    name: file.originalName,
                  },
                }) as FileArray),
                ''],

              modeOfTransport: undefined, // res.modeOfTransport,
              // company: typeof res.company === 'object' ? res.company?.id : res.company, // init should be undefined
              insuranceCurrency: res.insuranceCurrency,
              insuranceValue: res.insuranceValue,
            });

            setNotes(res.notes || '');
            handlePreferredDelivery({
              preferredDeliveryService: res.preferredDeliveryService,
              billingNumber: res.billingNumber || undefined,
              billingNumberCountryCode: res.billingNumberCountryCode || undefined,
              billingCode: res.billingCode || undefined,
              billingType: res.billingType || undefined,
            });
          } else {
            message.error('Wrong template id');
          }
        });
    }
  }, [searchParams.get('template')]);

  useMessageError([orderCreate, companiesGet, packagesGet, UNnumberGet, innerPackageGet]);

  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <FormContext.Provider value={{
      loading,
      formData,
      setForm,
      current,
      setFormData,
      setCurrent,
      createOrder,
      notes,
      setNotes,
      preferredDeliveryInfo,
      handlePreferredDelivery,
      packages,
      UNnumber,
      innerPackage,
      currency,
      setCurrency,
    }}
    >
      {children}
    </FormContext.Provider>
  );
}

export default FormProvider;

export const useContextForm = (): FormContextProps => useContext(FormContext);
