import { Form, FormInstance } from 'antd';
import { PackageItem, PackagesData } from '../../../../../../../hooks/api/order';

export const calcTotalPackages = (packagesItems: PackagesData[]) => (packagesItems || []).reduce((total, curr) => (
  total + (curr?.quantity || 1)
), 0);

export const calcTotalProducts = (packagesItems: PackagesData[]) => (packagesItems || []).reduce((total, curr) => (
  total + calcSinglePackageProduct(curr.items, curr.quantity)
  // (curr?.items || []).reduce((totalItems, currItem) => (
  //   totalItems + (currItem.quantity || 0)
  // ), 0)) * (curr?.quantity || 1)
), 0);

export const calcSinglePackageProduct = (packageItems: PackageItem[], quantity: number) => (
  (packageItems || []).reduce((totalItems, currItem) => (
    totalItems + (currItem.quantity || 0)
  ), 0) * (quantity || 1)
);

export const calcTotalWeight = (packagesItems: PackagesData[]) => (packagesItems || []).reduce((total, curr) => (
  total + (curr?.weight || 0)
), 0);

export const calcTotalValue = (packagesItems: PackagesData[]) => (packagesItems || []).reduce((total, curr) => (
  total + ((curr?.value || 0) * (curr?.quantity || 1))
), 0);

/** Components */
interface FormInstanceProp {
  form: FormInstance;
}

export function CollapseQuantity({ form, index }: { index: number } & FormInstanceProp) {
  const quantityWatch = Form.useWatch(['packages', index, 'quantity'], form) || '';

  return quantityWatch ? `${quantityWatch}x` : '';
}

export function CollapseTotalWeight({ form, index }: { index: number } & FormInstanceProp) {
  const weightWatch = Form.useWatch(['packages', index, 'weight'], form) || '';
  const quantityWatch = Form.useWatch(['packages', index, 'quantity'], form) || '';

  return weightWatch ? `${weightWatch * (quantityWatch || 1)} kg` : '';
}

export function CollapseKind({ form, index }: { index: number } & FormInstanceProp) {
  // const { forms: { ['packages']: form } } = useOrderContextForm();
  // const kindWatch: PackageKindType = (Form.useWatch(['packages', index, 'kind'], form) || '');

  return 'Own packaging';
  // return EnumPackageKind[kindWatch];
}

export function CollapseCount({ form, index }: { index: number } & FormInstanceProp) {
  const itemsWatch: PackageItem[] = Form.useWatch(['packages', index, 'items'], form) || [];
  const quantityWatch = Form.useWatch(['packages', index, 'quantity'], form) || '';

  return itemsWatch?.length > 0 ? `${calcSinglePackageProduct(itemsWatch, quantityWatch)} product(s)` : '';
}

export function CollapsePrice({ form, index }: { index: number } & FormInstanceProp) {
  const inputWatch = Form.useWatch(['packages', index, 'value'], form) || '';
  const currencyWatch = Form.useWatch(['packages', index, 'currency'], form) || '';
  const quantityWatch = Form.useWatch(['packages', index, 'quantity'], form) || '';

  return inputWatch ? `${(inputWatch || 0) * (quantityWatch || 1)} ${currencyWatch}` : '';
}

export function CollapseSizes({ form, index }: { index: number } & FormInstanceProp) {
  const length = Form.useWatch(['packages', index, 'length'], form);
  const width = Form.useWatch(['packages', index, 'width'], form);
  const height = Form.useWatch(['packages', index, 'height'], form);

  if (length) {
    return `${length || '-'} x ${width || '-'} x ${height || '-'} cm`;
  }

  return '';
}
