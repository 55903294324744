import { useState, ChangeEvent, useMemo } from 'react';
import { Input as AntdInput, Tooltip } from 'antd';
import clsx from 'clsx';
import { InfoCircleFilled } from '@ant-design/icons';
import floatStyles from '../index.module.scss';
import styles from './index.module.scss';

interface InputProps {
  label: string;
  value?: string;
  type?: string;
  required?: boolean;
  disabled?: boolean;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  hint?: string | undefined;
  maxLength?: number;
}

function Input({
  label, value, type, required, onChange, hint, disabled, maxLength,
}: InputProps) {
  const [focus, setFocus] = useState(false);
  const isOccupied = useMemo(() => (
    focus || !!(value && value.length > 0)
  ), [value, focus]);

  return (
    <div
      className={clsx(floatStyles.floatLabel, styles.disabled)}
      onBlur={() => setFocus(false)}
      onFocus={() => setFocus(true)}
    >
      <AntdInput
        variant="borderless"
        size="large"
        value={value || undefined}
        className={clsx(floatStyles.input, hint ? styles.hintInput : '')}
        onChange={onChange}
        type={type}
        disabled={disabled}
        addonAfter={
          hint ? <Tooltip className={styles.hint} title={hint}><InfoCircleFilled /></Tooltip> : undefined
        }
        maxLength={maxLength}
      />
      <label className={clsx(floatStyles.label, {
        [floatStyles.asLabel]: isOccupied,
        [floatStyles.asPlaceholder]: !isOccupied,
      })}
      >
        {label}
        {' '}
        {required ? <span className="text-danger">*</span> : null}
      </label>
    </div>
  );
}

Input.defaultProps = {
  value: '',
  type: 'text',
  required: false,
  disabled: false,
  hint: undefined,
  onChange: () => {},
};

export default Input;
