import { Steps } from 'antd';
import { useEffect } from 'react';
import Shipping from '../Steps/Shipping';
import { useContextForm } from '../Context';
import Importer from '../Steps/Importer';
import GoodsTab from '../Steps/GoodsTab';
import Overview from '../Steps/Overview';
import Confirmation from '../Components/Confirmation';

import styles from './index.module.scss';

export default function Wrapper(): React.ReactNode | null {
  const {
    current, setCurrent,
  } = useContextForm();
  const stepNames = ['shipper', 'importer', 'goods'];

  useEffect(() => {
    const container = document.getElementById('scrollContainer');

    if (container) { container.scrollTo({ top: 0, behavior: 'smooth' }); }
  }, [current]);

  const steps = [
    {
      title: 'Shipper',
      content: <Shipping />,
    },
    {
      title: 'Importer',
      content: <Importer />,
    },
    {
      title: 'Goods',
      content: <GoodsTab />,
    },
    {
      title: 'Overview',
      content: <Overview />,
    },
  ];

  const items = steps.map((item) => ({ key: item.title, title: item.title }));

  return (
    <div className={styles.wrapper}>
      {current < 4 && (
      <div className={styles.stepsWrap}>
        <Steps
          onChange={(step) => {
            // console.log(formData?.[stepNames?.[step]]);
            if (step < current) {
              // setCurrent(step);

              const container = document.getElementById('backButton');

              if (container) {
                container.scrollIntoView({ behavior: 'smooth', block: 'center' });

                /** Add pulsation animation class */
                container.classList.add('pulsate');

                /** Remove the class after the animation duration (e.g., 3 seconds) */
                setTimeout(() => {
                  container.classList.remove('pulsate');
                }, 2500); /** If change - adjust time to match animation duration in pulsate className */
              }
            }
          }}
          current={current}
          items={items}
          progressDot
          className={styles.steps}
          responsive={false}
        />
      </div>
      )}
      {current < 4 ? <div className={styles.content}>{steps[current].content}</div> : <Confirmation />}
    </div>
  );
}
